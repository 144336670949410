<template>
  <div class="page-loader">
    <div class="loader-container">
      <div class="loader-box"></div>
      <div class="loader-box"></div>
      <div class="loader-box"></div>
      <div class="loader-box"></div>
      <div class="loader-box"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreLoader",
};
</script>
